import { GlobeIcon, MailIcon } from '@heroicons/react/outline';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import Button, { BUTTON_SIZES } from 'components/atoms/Button';
import Input from 'components/atoms/Input';
// import FacebookIcon from 'components/icons/FacebookIcon';
// import GithubIcon from 'components/icons/GithubIcon';
// import TwitterIcon from 'components/icons/TwitterIcon';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { NOTIFICATION_TYPES, useUIStore } from 'store/uiStore';
import { tw } from 'utils';
import * as yup from 'yup';

const schema = (t) =>
  yup.object().shape({
    name: yup.string().max(140).required(t('marketing.inquiry-form.validation.name')),
    lastName: yup.string().max(140).required(t('marketing.inquiry-form.validation.lastName')),
    email: yup.string().email().max(140).required(t('marketing.inquiry-form.validation.email')),
    phone: yup.string(),
    subject: yup.string().max(140).required(t('marketing.inquiry-form.validation.subject')),
    message: yup.string().max(500).required(t('marketing.inquiry-form.validation.message'))
  });

export default function InquiryForm() {
  return (
    <div id={'inquiry'} className={tw('bg-gray-50')}>
      <div className={tw('max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8')}>
        <div className={tw('relative bg-white shadow-xl')}>
          <h2 className="sr-only">Contact us</h2>

          <div className={tw('grid grid-cols-1 lg:grid-cols-3')}>
            {/* Contact information */}

            <FormGradientBackground>
              <SideInformation />
            </FormGradientBackground>

            {/* Contact form */}
            <ContactForm />
          </div>
        </div>
      </div>
    </div>
  );
}

function SideInformation() {
  const { t } = useTranslation();

  return (
    <>
      <h3 className={tw('text-lg font-medium text-white')}>
        {t('marketing.inquiry-form.side-information.title')}
      </h3>
      <p className={tw('mt-6 text-base text-indigo-50 max-w-3xl')}>
        {t('marketing.inquiry-form.side-information.description')}
      </p>
      <dl className={tw('mt-8 space-y-6')}>
        <dt>
          <span className="sr-only">Web url</span>
        </dt>
        <dd className={tw('flex text-base text-indigo-50')}>
          <GlobeIcon className={tw('flex-shrink-0 w-6 h-6 text-indigo-200')} aria-hidden="true" />
          <span className={tw('ml-3')}>https://vidohouse.com</span>
        </dd>
        <dt>
          <span className="sr-only">Email</span>
        </dt>
        <dd className={tw('flex text-base text-indigo-50')}>
          <MailIcon className={tw('flex-shrink-0 w-6 h-6 text-indigo-200')} aria-hidden="true" />
          <span className={tw('ml-3')}>hello@vidohouse.com</span>
        </dd>
      </dl>
      {/* <ul className={'mt-8 flex space-x-12'}>
        <li>
          <a className={tw('text-indigo-200 hover:text-indigo-100')} href="#">
            <span className="sr-only">Facebook</span>
            <FacebookIcon />
          </a>
        </li>
        <li>
          <a className={tw('text-indigo-200 hover:text-indigo-100')} href="#">
            <span className="sr-only">Github</span>
            <GithubIcon url="#" />
          </a>
        </li>
        <li>
          <a className={tw('text-indigo-200 hover:text-indigo-100')} href="#">
            <span className="sr-only">Twitter</span>
            <TwitterIcon url="#" />
          </a>
        </li>
      </ul> */}
    </>
  );
}
function ContactForm() {
  const { t } = useTranslation();

  const { setNotification } = useUIStore((state) => ({
    setNotification: state.setNotification
  }));
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema(t))
  });
  async function onSubmit(data) {
    try {
      const res = await fetch('/api/contact-inquiry', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      }).then((res) => res.json());

      if (res.status === 'ok') {
        reset();
        setNotification({
          status: NOTIFICATION_TYPES.Success,
          title: t('marketing.inquiry-form.contact-form.email-sent')
        });
      } else {
        setNotification({
          status: NOTIFICATION_TYPES.Error,
          title: t('commons.something-went-wrong'),
          info: t('commons.contact-support')
        });
        window?.tidioChatApi?.open?.();
      }
    } catch {
      setNotification({
        status: NOTIFICATION_TYPES.Error,
        title: t('commons.something-went-wrong'),
        info: t('commons.contact-support')
      });
      window?.tidioChatApi?.open?.();
    }
  }
  return (
    <div className={tw('py-10 px-6 sm:px-10 lg:col-span-2 xl:p-12')}>
      <h3 className={tw('text-lg font-medium text-gray-900')}>
        {t('marketing.inquiry-form.contact-form.title')}
      </h3>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={tw('mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8')}>
        <Input
          label={t('marketing.inquiry-form.contact-form.first-name')}
          autoComplete="given-name"
          formProps={register('name')}
          inputClasses={tw('py-3 px-4')}
          errorText={errors?.name?.message}
        />

        <Input
          label={t('marketing.inquiry-form.contact-form.last-name')}
          autoComplete="family-name"
          formProps={register('lastName')}
          inputClasses={tw('py-3 px-4')}
          errorText={errors?.lastName?.message}
        />

        <Input
          label={t('marketing.inquiry-form.contact-form.email')}
          autoComplete="email"
          formProps={register('email')}
          inputClasses={tw('py-3 px-4')}
          errorText={errors?.email?.message}
        />

        <Input
          label={t('marketing.inquiry-form.contact-form.phone')}
          autoComplete="tel"
          formProps={register('phone')}
          optional
          inputClasses={tw('py-3 px-4')}
          errorText={errors?.phone?.message}
        />

        <div className={tw('sm:col-span-2')}>
          <Input
            label={t('marketing.inquiry-form.contact-form.subject')}
            formProps={register('subject')}
            full
            inputClasses={tw('py-3 px-4')}
            errorText={errors?.subject?.message}
          />
        </div>
        <div className={tw('sm:col-span-2')}>
          <Input
            label={t('marketing.inquiry-form.contact-form.message')}
            formProps={register('message')}
            textarea
            rowCount={5}
            full
            inputClasses={tw('py-3 px-4')}
            errorText={errors?.message?.message}
            InfoComponent={t('commons.max-character', { count: 500 })}
          />
        </div>
        <div className={tw('sm:col-span-2 sm:flex sm:justify-end')}>
          <Button
            text={t('commons.submit')}
            layoutClasses={tw('mt-2')}
            size={BUTTON_SIZES.xl}
            onClick={handleSubmit(onSubmit)}
          />
        </div>
      </form>
    </div>
  );
}

function FormGradientBackground({ children }) {
  return (
    <>
      {/* Contact information */}
      <div className={tw('relative overflow-hidden py-10 px-6 bg-indigo-700 sm:px-10 xl:p-12')}>
        <div className={tw('absolute inset-0 pointer-events-none sm:hidden')} aria-hidden="true">
          <svg
            className={'absolute inset-0 w-full h-full'}
            width={343}
            height={388}
            viewBox={'0 0 343 388'}
            fill={'none'}
            preserveAspectRatio={'xMidYMid slice'}
            xmlns={'http://www.w3.org/2000/svg'}>
            <path
              d={'M-99 461.107L608.107-246l707.103 707.107-707.103 707.103L-99 461.107z'}
              fill={'url(#linear1)'}
              fillOpacity={'.1'}
            />
            <defs>
              <linearGradient
                id={'linear1'}
                x1={'254.553'}
                y1={'107.554'}
                x2={'961.66'}
                y2={'814.66'}
                gradientUnits={'userSpaceOnUse'}>
                <stop stopColor={'#fff'} />
                <stop offset={1} stopColor={'#fff'} stopOpacity={0} />
              </linearGradient>
            </defs>
          </svg>
        </div>
        <div
          className={
            'hidden absolute top-0 right-0 bottom-0 w-1/2 pointer-events-none sm:block lg:hidden'
          }
          aria-hidden="true">
          <svg
            className={'absolute inset-0 w-full h-full'}
            width={359}
            height={339}
            viewBox={'0 0 359 339'}
            fill={'none'}
            preserveAspectRatio={'xMidYMid slice'}
            xmlns={'http://www.w3.org/2000/svg'}>
            <path
              d={'M-161 382.107L546.107-325l707.103 707.107-707.103 707.103L-161 382.107z'}
              fill={'url(#linear2)'}
              fillOpacity={'.1'}
            />
            <defs>
              <linearGradient
                id={'linear2'}
                x1={'192.553'}
                y1={'28.553'}
                x2={'899.66'}
                y2={'735.66'}
                gradientUnits={'userSpaceOnUse'}>
                <stop stopColor={'#fff'} />
                <stop offset={1} stopColor={'#fff'} stopOpacity={0} />
              </linearGradient>
            </defs>
          </svg>
        </div>
        <div
          className={'hidden absolute top-0 right-0 bottom-0 w-1/2 pointer-events-none lg:block'}
          aria-hidden="true">
          <svg
            className={'absolute inset-0 w-full h-full'}
            width={160}
            height={678}
            viewBox={'0 0 160 678'}
            fill={'none'}
            preserveAspectRatio={'xMidYMid slice'}
            xmlns={'http://www.w3.org/2000/svg'}>
            <path
              d={'M-161 679.107L546.107-28l707.103 707.107-707.103 707.103L-161 679.107z'}
              fill={'url(#linear3)'}
              fillOpacity={'.1'}
            />
            <defs>
              <linearGradient
                id={'linear3'}
                x1={'192.553'}
                y1={'325.553'}
                x2={'899.66'}
                y2={'1032.66'}
                gradientUnits={'userSpaceOnUse'}>
                <stop stopColor={'#fff'} />
                <stop offset={1} stopColor={'#fff'} stopOpacity={0} />
              </linearGradient>
            </defs>
          </svg>
        </div>

        {children}
      </div>
    </>
  );
}
