import { Disclosure } from '@headlessui/react';
import { BellIcon } from '@heroicons/react/outline';
import UserAvatar from 'components/atoms/UserAvatar';
import useUser from 'hooks/useUser';
import PropTypes from 'prop-types';
import { tw } from 'utils';

NavbarMenuMobile.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func
    })
  ).isRequired
};

export default function NavbarMenuMobile({ options }) {
  const { user } = useUser();

  return (
    <Disclosure.Panel className={tw('lg:hidden')}>
      {/* <div className="pt-2 pb-3 space-y-1">
        <NavbarMobileMenuItem to="/home" text="Home" state="active" />
        <NavbarMobileMenuItem to="/careers" text="Careers" state="default" />
        <NavbarMobileMenuItem to="/tos" text="Terms&Conditions" state="default" />
      </div> */}
      <div className={tw('pt-4 pb-3 border-t border-gray-200')}>
        <div className={tw('flex items-center px-4')}>
          <div className={tw('flex-shrink-0')}>
            <UserAvatar profilePhoto={user?.profileImageUrl} />
          </div>
          <div className={tw('ml-3')}>
            <div className={tw('text-base font-medium text-gray-800')}>{user?.name}</div>
            <div className={tw('text-sm font-medium text-gray-500')}>{user?.email}</div>
          </div>
          <button
            className={tw(
              'ml-auto flex-shrink-0 bg-white p-1 text-gray-400 rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            )}>
            <span className="sr-only">View notifications</span>
            <BellIcon className={tw('h-6 w-6')} aria-hidden="true" />
          </button>
        </div>
        <div className={tw('mt-3 space-y-1')}>
          {options.map((option, idx) => (
            <a
              key={idx}
              href={option.label}
              onClick={(event) => {
                event.preventDefault();
                option.onClick({ option });
              }}
              className={tw(
                'block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100'
              )}>
              {option.label}
            </a>
          ))}
        </div>
      </div>
    </Disclosure.Panel>
  );
}
