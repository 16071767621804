// import { ClockIcon } from '@heroicons/react/outline';
import Adornment from 'components/atoms/Adornment';
import Button, { BUTTON_SIZES, BUTTON_TYPES } from 'components/atoms/Button';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import { tw } from 'utils';
import { dateDisplayFormat } from 'utils/dateUtils';
export default function Featured({ workshop }) {
  const { t } = useTranslation();
  const { push } = useRouter();

  return (
    <div id={'featured'} className={tw('relative py-16 bg-white')}>
      <div className={tw('hidden absolute top-0 inset-x-0 h-1/2 lg:block')} aria-hidden="true" />
      <div className={tw('max-w-7xl mx-auto bg-indigo-600 lg:bg-transparent lg:px-8')}>
        <div className={tw('lg:grid lg:grid-cols-12')}>
          <div
            className={tw(
              'relative z-10 lg:col-start-1 lg:row-start-1 lg:col-span-4 lg:py-16 lg:bg-transparent'
            )}>
            <div
              className={tw('absolute inset-x-0 h-1/2 bg-gray-50 lg:hidden')}
              aria-hidden="true"
            />
            <div className={tw('max-w-md mx-auto px-4 sm:max-w-3xl sm:px-6 lg:max-w-none lg:p-0')}>
              <div
                className={tw('aspect-w-10 aspect-h-6 sm:aspect-w-2 sm:aspect-h-1 lg:aspect-w-1')}>
                <img
                  className={tw('object-cover object-center rounded-3xl shadow-2xl')}
                  src={workshop?.mainImg || '/assets/building_workshop/building-1.svg'}
                  alt={'featured workshop'}
                />
              </div>
            </div>
          </div>

          <div
            className={tw(
              'relative bg-indigo-600 lg:col-start-3 lg:row-start-1 lg:col-span-10 lg:rounded-3xl lg:grid lg:grid-cols-10 lg:items-center'
            )}>
            <div
              className={tw('hidden absolute inset-0 overflow-hidden rounded-3xl lg:block')}
              aria-hidden="true">
              <Adornment
                color={'text-indigo-500'}
                layoutClass={tw(
                  'absolute bottom-full left-full transform translate-y-1/3 -translate-x-2/3 xl:bottom-auto xl:top-0 xl:translate-y-0'
                )}
              />
              <Adornment
                color={'text-indigo-500'}
                layoutClass={tw(
                  'absolute top-full transform -translate-y-1/3 -translate-x-1/3 xl:-translate-y-1/2'
                )}
              />
            </div>
            {workshop && (
              <div
                className={tw(
                  'relative max-w-md mx-auto py-12 px-4 space-y-6 sm:max-w-3xl sm:py-16 sm:px-6 lg:max-w-none lg:p-0 lg:col-start-4 lg:col-span-6'
                )}>
                {/* <p className={tw('text-2xl font-extrabold text-white')}>
                Featured:{' '}
                <time dateTime={dateDisplayFormat(workshop?.date)}>
                  {dateDisplayFormat(workshop?.date)}
                </time>
              </p> */}
                <h2
                  className={tw('text-3xl font-extrabold text-white line-clamp-2')}
                  id={'join-heading'}>
                  {/* <span>Featured: </span> */}
                  Featured: {workshop?.title}
                  <div className={tw('flex text-xs text-gray-200 font-medium')}>
                    <time dateTime={dateDisplayFormat(workshop?.date)}>
                      {dateDisplayFormat(workshop?.date)}
                    </time>
                  </div>
                </h2>
                <p className={tw('text-lg text-white line-clamp-2')}>{workshop?.summary}</p>
                <div>
                  <div className={tw('flex text-sm text-gray-50 line-clamp-6')}>
                    {workshop?.talentSummary}
                  </div>
                  {/* <div className={tw('text-red-500 flex items-center mt-2 -ml-1')}>
                    <ClockIcon className={tw('h-4 w-4')} aria-hidden="true" />
                    <p className={tw('text-xs ml-1')}>
                      {t('workshop.registration.spots-left', { count: workshop?.spotRemaining })}
                    </p>
                  </div> */}
                </div>
                <Button
                  size={BUTTON_SIZES.xl}
                  type={BUTTON_TYPES.TextBordered}
                  text={t('marketing.featured.action-button-text')}
                  onClick={() => push(`/w/${workshop?.displayUrl}`)}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
