import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { tw } from 'utils';

Input.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  errorText: PropTypes.string,
  type: PropTypes.string,
  required: PropTypes.bool,
  InfoComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  defaultValue: PropTypes.string,
  innerInfo: PropTypes.string,
  disabled: PropTypes.bool,
  rowCount: PropTypes.number,
  RightIcon: PropTypes.element,
  onChange: PropTypes.func,
  value: PropTypes.string,
  autoComplete: PropTypes.string,
  inputClasses: PropTypes.string,
  optional: PropTypes.bool,
  full: PropTypes.bool
};

export default function Input({
  label,
  placeholder = '',
  errorText,
  formProps = {},
  type = 'text',
  required = false,
  InfoComponent,
  textarea = false,
  layoutClasses = '',
  defaultValue = '',
  innerInfo,
  disabled = false,
  rowCount = 3,
  RightIcon,
  onChange = () => {},
  value,
  autoComplete = '',
  inputClasses = '',
  optional = false,
  full
}) {
  const { t } = useTranslation();

  const commonClasses = tw('block w-full', 'sm:text-sm');
  const defaultClass = tw(
    commonClasses,
    'shadow-sm focus:ring-indigo-500 focus:border-indigo-500   border-gray-300'
  );
  const errorClasses = tw(
    commonClasses,
    ' pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 '
  );

  const inputClass = tw(
    errorText ? errorClasses : defaultClass,
    innerInfo ? 'rounded-none rounded-r-md' : 'rounded-md',
    disabled && 'bg-indigo-50 cursor-not-allowed',
    inputClasses
  );

  return (
    <div className={layoutClasses}>
      <div className={tw('flex justify-between')}>
        <label className={tw('block text-sm font-medium text-gray-500')}>{label}</label>
        {optional && <span className={tw('text-sm text-gray-500')}>{t('commons.optional')}</span>}
      </div>
      <div className={tw('mt-1')}>
        {textarea ? (
          <textarea
            disabled={disabled}
            rows={rowCount}
            onChange={onChange}
            placeholder={placeholder}
            value={value}
            autoComplete={autoComplete}
            //  className="max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
            className={inputClass}
            defaultValue={defaultValue}
            // eslint-disable-next-line react/jsx-no-literals
            aria-describedby={`${label}-description`}
            {...formProps}
          />
        ) : (
          <div className={tw(!full && 'max-w-lg', 'flex rounded-md shadow-sm')}>
            {innerInfo && (
              <span
                className={tw(
                  errorText ? 'border-red-300 ' : 'border-gray-300',
                  'inline-flex items-center px-3 rounded-l-md border border-r-0 bg-gray-50 text-gray-500 sm:text-sm'
                )}>
                {innerInfo}
              </span>
            )}
            <span className={tw('relative w-full')}>
              <input
                value={value}
                disabled={disabled}
                required={required}
                defaultValue={defaultValue}
                onChange={onChange}
                type={type}
                autoComplete={autoComplete}
                className={inputClass}
                placeholder={placeholder}
                // eslint-disable-next-line react/jsx-no-literals
                aria-describedby={`${label}-description`}
                {...formProps}
              />
              {RightIcon && (
                <div
                  className={tw(
                    'absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none'
                  )}>
                  <RightIcon
                    className={tw('h-5 w-5 text-gray-400 transform rotate-45')}
                    aria-hidden="true"
                  />
                </div>
              )}
            </span>
          </div>
        )}
      </div>
      {errorText && <p className={tw('mt-2 text-sm text-red-600')}>{errorText}</p>}
      {typeof InfoComponent === 'string' && !errorText && (
        <span className={tw('mt-2 text-sm text-gray-700')}>{InfoComponent}</span>
      )}
      {typeof InfoComponent === 'function' && <InfoComponent />}
    </div>
  );
}
