import { Disclosure } from '@headlessui/react';
import { BellIcon, ChevronDownIcon } from '@heroicons/react/outline';
import Button, { BUTTON_SIZES, BUTTON_TYPES } from 'components/atoms/Button';
import Dropdown from 'components/atoms/Dropdown';
// import SearchInput from 'components/atoms/SearchInput';
import NavbarHamburger from 'components/atoms/NavbarHamburger';
import UserAvatar from 'components/atoms/UserAvatar';
import NavbarMenuMobile from 'components/orgamisms/NavbarMenuMobile';
import WorkshopSearchInput from 'components/orgamisms/WorkshopSearchInput';
import { NAVIGATION_ROUTES } from 'constants/navigationPaths';
import useAuth from 'hooks/useAuth';
import useUser from 'hooks/useUser';
import { useRouter } from 'next/router';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { tw } from 'utils';

export default function NavbarHeader() {
  const { push } = useRouter();
  const { signOut } = useAuth();
  const { user } = useUser();
  const { t } = useTranslation();

  // const titles = [
  // { label: t('navbarHeader.welcome'), to: 'hero' },
  // { label: t('navbarHeader.featured'), to: 'featured' },
  // { label: t('navbarHeader.upcoming'), to: 'eventList' },
  // { label: t('navbarHeader.faq'), to: 'faq' },
  // { label: t('navbarHeader.blog'), path: NAVIGATION_ROUTES.Blog }
  // ];

  // const [currentView, setCurrentView] = useState(titles[0]?.label);
  const dropdownOptions = [
    {
      label: t('commons.your-profile'),
      onClick: () => push(NAVIGATION_ROUTES.Settings)
    },
    {
      label: user?.uid ? t('commons.sign-out') : t('commons.sign-in'),
      onClick: user?.uid ? signOut : () => push(NAVIGATION_ROUTES.SignIn)
    }
  ];

  // function onClickMenuItemHandler(title) {
  //   if (title.path) return push(title.path);
  //   setCurrentView(title.label);
  //   var el = document.getElementById(title.to);
  //   if (!el) return;
  //   el.scrollIntoView({ behavior: 'smooth' });
  // }

  return (
    <Disclosure as="nav" className={tw('bg-white shadow fixed top-0 z-20 w-screen')}>
      {({ open }) => (
        <>
          <div className={tw('max-w-7xl mx-auto px-2 sm:px-4 lg:px-8')}>
            <div className={tw('flex justify-between h-16')}>
              <div className={tw('flex px-2 lg:px-0   flex-1 w-full')}>
                <div className={tw('flex-shrink-0 flex items-center')}>
                  <img
                    className={tw('block sm:hidden h-6 w-auto')}
                    src={'/assets/logo/logo-mini-black.svg'}
                    alt={'vidohouse'}
                  />
                  <img
                    className={'hidden sm:block w-auto h-10'}
                    src={'/assets/logo/logo-full.svg'}
                    alt="vidohouse logo"
                  />
                </div>

                <div className={tw('flex flex-1 w-full')}>
                  <WorkshopSearchInput displaySearchResult />
                </div>

                {/* <div className={tw('hidden lg:ml-6 lg:flex lg:space-x-8')}>
                  {titles.map((title) => (
                    <NavbarDesktopMenuItem
                      key={title.label}
                      text={title.label}
                      state={title.label === currentView ? 'active' : 'default'}
                      onClick={() => onClickMenuItemHandler(title)}
                    />
                  ))}
                </div> */}
              </div>
              {/* <SearchInput /> */}
              <div className={tw('flex items-center sm:hidden')}>
                {/* Mobile menu button */}
                <Button
                  onClick={() => push(NAVIGATION_ROUTES.Workshops)}
                  text={t('navbarHeader.workshops')}
                  size={BUTTON_SIZES.md}
                  type={BUTTON_TYPES.TextBordered}
                  layoutClasses={'mr-2'}
                />
                <NavbarHamburger open={open} />
              </div>
              {user?.uid && (
                <div className={tw('hidden sm:ml-4 sm:flex sm:items-center')}>
                  <Button
                    onClick={() => push(NAVIGATION_ROUTES.Workshops)}
                    text={t('navbarHeader.workshops')}
                    size={BUTTON_SIZES.md}
                    type={BUTTON_TYPES.TextBordered}
                    layoutClasses={'mr-2'}
                  />
                  <Button type={BUTTON_TYPES.TextGray}>
                    <span className="sr-only">View notifications</span>
                    <BellIcon className={'h-6 w-6'} aria-hidden="true" />
                  </Button>

                  <Dropdown
                    onSelect={(option) => option.onClick()}
                    options={dropdownOptions}
                    rounded
                    position={'right'}
                    layoutClasses={'ml-2'}>
                    {() => (
                      <>
                        <UserAvatar profilePhoto={user?.profileImageUrl} />
                        <span
                          className={tw(
                            'hidden ml-3 text-gray-700 text-sm font-medium lg:block self-center	'
                          )}>
                          <span className="sr-only">Open user menu for </span>
                          {user?.email}
                        </span>
                        <ChevronDownIcon
                          className={tw(
                            'hidden flex-shrink-0 ml-1 h-5 w-5 text-gray-400 lg:block self-center	'
                          )}
                          aria-hidden="true"
                        />
                      </>
                    )}
                  </Dropdown>
                </div>
              )}
              {!user?.uid && (
                <div className={tw('hidden md:flex items-center justify-end md:flex-1 lg:w-0')}>
                  <Button
                    onClick={() => push(NAVIGATION_ROUTES.Workshops)}
                    text={t('navbarHeader.workshops')}
                    size={BUTTON_SIZES.md}
                    type={BUTTON_TYPES.TextBordered}
                    layoutClasses={'mr-6'}
                  />
                  <Button
                    onClick={() => push(NAVIGATION_ROUTES.SignIn)}
                    text={t('commons.sign-in')}
                    size={BUTTON_SIZES.xl}
                    type={BUTTON_TYPES.TextGray}
                    layoutClasses={'mr-8'}
                  />
                  <Button
                    onClick={() => push(NAVIGATION_ROUTES.SignUp)}
                    text={t('commons.sign-up')}
                    size={BUTTON_SIZES.lg}
                    type={BUTTON_TYPES.Primary}
                  />
                </div>
              )}
            </div>
          </div>

          <NavbarMenuMobile options={dropdownOptions} />
        </>
      )}
    </Disclosure>
  );
}
