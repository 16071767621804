import Badge from 'components/atoms/Badge';
import { NAVIGATION_ROUTES } from 'constants/navigationPaths';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { tw } from 'utils';

BlogPreview.propTypes = {
  post: PropTypes.shape({
    title: PropTypes.string.isRequired,
    categories: PropTypes.any,
    imageUrl: PropTypes.string,
    description: PropTypes.string,
    author: PropTypes.shape({
      profileImageUrl: PropTypes.string,
      name: PropTypes.string,
      email: PropTypes.string,
      title: PropTypes.string
    }),
    summary: PropTypes.string,
    createdAt: PropTypes.date,
    readingTime: PropTypes.string || PropTypes.number
  })
};

export default function BlogPreview({ post }) {
  const { t } = useTranslation();
  const { push } = useRouter();

  return (
    <div key={post.title} className={tw('flex flex-col rounded-lg shadow-lg overflow-hidden')}>
      <button
        className={tw('flex-shrink-0 focus:outline-none outline-none')}
        onClick={() => push(NAVIGATION_ROUTES.BlogPost({ slug: post.slug }))}>
        <img className={tw('h-48 w-full object-cover')} src={post.imageUrl} alt={'blog cover'} />
      </button>
      <div className={tw('flex-1 bg-white p-6 flex flex-col justify-between')}>
        <div className={tw('flex-1')}>
          {post.categories && (
            <div className={tw('flex space-x-2 mb-3')}>
              {post.categories.map((cat) => (
                <Badge label={cat} key={cat} />
              ))}
            </div>
          )}

          <p className={tw('text-xl font-semibold text-gray-900')}>{post.title}</p>
          <p className={tw('mt-3 text-base text-gray-500')}>{post.summary}</p>
        </div>
        <div className={tw('mt-6 flex items-center')}>
          <div className={tw('flex-shrink-0')}>
            <a href={post.author.href}>
              <span className="sr-only">{post.author.name}</span>
              <img
                className={tw('h-10 w-10 rounded-full')}
                src={post.author.profileImageUrl}
                alt={'author avatar'}
              />
            </a>
          </div>
          <div className={tw('ml-3')}>
            <p className={tw('text-sm font-medium text-gray-900')}>
              <a href={post.author.email} className={tw('hover:underline')}>
                {post.author.name}
              </a>
            </p>
            <div className={tw('flex space-x-1 text-sm text-gray-500')}>
              <time dateTime={post.createdAt}>{post.createdAt}</time>
              <span aria-hidden="true">&middot;</span>
              <span>
                {t('marketing.blog.item.reading-time', { readingTime: post.readingTime })}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
