/* eslint-disable react/jsx-no-literals */
import { useRouter } from 'next/router';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { tw } from 'utils';

export default function Hero() {
  const { t } = useTranslation();
  const { push } = useRouter();
  const [search, setSearch] = useState('');
  function onClickExplore() {
    setSearch('');
    push(`/workshops?search=${search}`);
  }
  return (
    <div id="hero" className="relative bg-white overflow-hidden mb-8 mt-4">
      <div className="hidden lg:block" aria-hidden="true">
        <HeroAdornment />
      </div>
      <section className="mt-4 sm:mt-12 lg:mt-12 px-4 sm:px-6 mx-auto max-w-7xl z-10">
        <div className="lg:grid lg:grid-cols-12 lg:gap-8">
          <div className="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
            <h1>
              <span className="block text-sm font-semibold uppercase tracking-wide text-gray-500 sm:text-base lg:text-sm xl:text-base">
                {t('marketing.hero.coming-soon')}
              </span>
              <span className="mt-1 block text-4xl tracking-tight font-extrabold sm:text-5xl xl:text-6xl">
                <span className="block text-gray-900"> {t('marketing.hero.title-up')}</span>
                <span className="block text-indigo-600"> {t('marketing.hero.title-down')}</span>
              </span>
            </h1>
            <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
              {t('marketing.hero.text')}
            </p>
            <div className="mt-8 sm:max-w-lg sm:mx-auto sm:text-center lg:text-left lg:mx-0">
              <p className="text-base font-medium text-gray-900">
                {t('marketing.hero.action-label')}
              </p>

              <form onSubmit={(event) => event.preventDefault()} className="mt-3 sm:flex">
                <label htmlFor="email" className="sr-only">
                  {t('marketing.hero.input-label')}
                </label>
                <input
                  type="text"
                  name="email"
                  id="email"
                  onChange={(event) => setSearch(event.target.value)}
                  className="block w-full py-3 text-base rounded-md placeholder-gray-500 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:flex-1 border-gray-300"
                  placeholder={t('marketing.hero.input-placeholder')}
                />
                <button
                  type="submit"
                  onClick={onClickExplore}
                  className="mt-3 w-full px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gray-800 shadow-sm hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:flex-shrink-0 sm:inline-flex sm:items-center sm:w-auto">
                  {t('marketing.hero.action-button-text')}
                </button>
              </form>
              <p className="mt-3 text-sm text-gray-500">
                {t('marketing.hero.action-info-text')}{' '}
                <a
                  href="/privacy"
                  target={'_blank'}
                  className="font-medium text-gray-900 underline">
                  {t('commons.privacy-policy')}
                </a>
                .
              </p>
            </div>
          </div>
          <div className="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
            <MobileAdornment />

            <div className="relative mx-auto w-full rounded-lg shadow-lg lg:max-w-md">
              <button
                type="button"
                className="relative block w-full bg-white rounded-lg overflow-hidden focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                <span className="sr-only">Watch our video to learn more</span>
                <img
                  className={tw('h-60 w-full')}
                  src={'/assets/meeting/girl-chair-pc.svg'}
                  alt={'Girl with PC'}
                />
                {/* <div
                  className="absolute inset-0 w-full h-full flex items-center justify-center"
                  aria-hidden="true">
                  <svg
                    className="h-20 w-20 text-indigo-500"
                    fill="currentColor"
                    viewBox="0 0 84 84">
                    <circle opacity="0.9" cx={42} cy={42} r={42} fill="white" />
                    <path d="M55.5039 40.3359L37.1094 28.0729C35.7803 27.1869 34 28.1396 34 29.737V54.263C34 55.8604 35.7803 56.8131 37.1094 55.9271L55.5038 43.6641C56.6913 42.8725 56.6913 41.1275 55.5039 40.3359Z" />
                  </svg>
                </div> */}
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
function MobileAdornment() {
  return (
    <svg
      className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-8 scale-75 origin-top sm:scale-100 lg:hidden"
      width={640}
      height={784}
      fill="none"
      viewBox="0 0 640 784"
      aria-hidden="true">
      <defs>
        <pattern
          id="4f4f415c-a0e9-44c2-9601-6ded5a34a13e"
          x={118}
          y={0}
          width={20}
          height={20}
          patternUnits="userSpaceOnUse">
          <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
        </pattern>
      </defs>
      <rect y={72} width={640} height={640} className="text-gray-50" fill="currentColor" />
      <rect x={118} width={404} height={784} fill="url(#4f4f415c-a0e9-44c2-9601-6ded5a34a13e)" />
    </svg>
  );
}
function HeroAdornment() {
  return (
    <svg
      className="absolute top-0 left-1/2 transform translate-x-64 -translate-y-8"
      width={640}
      height={784}
      fill="none"
      viewBox="0 0 640 784">
      <defs>
        <pattern
          id="9ebea6f4-a1f5-4d96-8c4e-4c2abf658047"
          x={118}
          y={0}
          width={20}
          height={20}
          patternUnits="userSpaceOnUse">
          <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
        </pattern>
      </defs>
      <rect y={72} width={640} height={640} className="text-gray-50" fill="currentColor" />
      <rect x={118} width={404} height={784} fill="url(#9ebea6f4-a1f5-4d96-8c4e-4c2abf658047)" />
    </svg>
  );
}
