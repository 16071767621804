import { times } from 'lodash';
import { useTranslation } from 'react-i18next';
import { tw } from 'utils';
export default function Faq() {
  const { t } = useTranslation();
  const faqs = times(6, (index) => ({
    id: index,
    question: t(`marketing.faq.${index + 1}.q`),
    answer: t(`marketing.faq.${index + 1}.a`)
  }));
  return (
    <div id={'faq'} className={tw('bg-indigo-700')}>
      <div className={tw('max-w-7xl', 'mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8')}>
        <h2 className={tw('text-3xl font-extrabold text-white')}>{t('marketing.faq.title')}</h2>
        <div className={tw('mt-6 border-t border-indigo-300 border-opacity-25 pt-10')}>
          <dl
            className={tw(
              'space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:grid-rows-2 md:gap-x-8 md:gap-y-12'
            )}>
            {faqs.map((faq) => (
              <div key={faq.id}>
                <dt className={tw('text-lg leading-6 font-medium text-white')}>{faq.question}</dt>
                <dd className={tw('mt-2 text-base text-indigo-200')}>{faq.answer}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
