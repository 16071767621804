import { SpeakerphoneIcon, XIcon } from '@heroicons/react/outline';
import Button, { BUTTON_SIZES, BUTTON_TYPES } from 'components/atoms/Button';
import PropTypes from 'prop-types';
import { tw } from 'utils';
import { screenIs } from 'utils/uiUtils';

Banner.propTypes = {
  onClickAction: PropTypes.func,
  position: PropTypes.string,
  actionText: PropTypes.string
};
export const BANNER_POSITIONS = {
  Default: 'default',
  Bottom: 'bottom',
  Top: 'top'
};
export default function Banner({
  onClickAction,
  position = BANNER_POSITIONS.Default,
  text,
  actionText,
  onClose
}) {
  const isSmall = !screenIs('sm');
  const positions = {
    [BANNER_POSITIONS.Top]: 'fixed top pt-2 sm:pt-5',
    [BANNER_POSITIONS.Bottom]: 'fixed bottom-0 pb-2 sm:pb-5',
    [BANNER_POSITIONS.Default]: 'inset-x-0 pt-2 pb-2 sm:pt-5 sm:pb-5'
  };

  const bannerPositionCSS = positions[position] ?? positions.default;
  return (
    <div className={tw('inset-x-0', bannerPositionCSS)}>
      <div className={tw('max-w-7xl', 'mx-auto px-2 sm:px-6 lg:px-8')}>
        <div className={tw('p-2  sm:p-3', 'rounded-lg', 'bg-indigo-600 shadow-lg')}>
          <div className={tw('flex items-center justify-between flex-wrap')}>
            <div className={tw('flex-1 flex items-center', 'w-0')}>
              <span className={tw('flex p-2 rounded-lg bg-indigo-800')}>
                <SpeakerphoneIcon className={tw('h-6 w-6 text-white')} aria-hidden="true" />
              </span>
              <p className={tw('ml-3 sm:font-medium text-white text-sm sm:text-base')}>
                <span>{text}</span>
              </p>
            </div>
            {onClickAction && (
              <div
                className={tw(
                  'flex-shrink-0 w-full sm:w-auto',
                  'sm:mt-0 mt-2',
                  'order-3  sm:order-2  '
                )}>
                <Button
                  type={BUTTON_TYPES.Secondary}
                  full={isSmall}
                  text={actionText}
                  onClick={onClickAction}
                />
              </div>
            )}
            <div className={tw('order-2 flex-shrink-0 sm:order-3 sm:ml-2')}>
              <Button size={BUTTON_SIZES.xs} onClick={onClose}>
                <span className="sr-only">Dismiss</span>
                <XIcon className={tw('h-6 w-6 text-white')} aria-hidden="true" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
