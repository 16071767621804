import { Disclosure } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/solid';
import PropTypes from 'prop-types';
import { tw } from 'utils';

NavbarHamburger.propTypes = {
  open: PropTypes.bool
};

export default function NavbarHamburger({ open }) {
  return (
    <Disclosure.Button
      className={tw(
        'inline-flex items-center justify-center ',
        'p-2',
        'rounded-md   focus:outline-none focus:ring-2 focus:ring-inset',
        'text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:ring-indigo-500'
      )}>
      <span className="sr-only">Open main menu</span>
      {open ? (
        <XIcon className={tw('block h-6 w-6')} aria-hidden="true" />
      ) : (
        <MenuIcon className={tw('block h-6 w-6')} aria-hidden="true" />
      )}
    </Disclosure.Button>
  );
}
