import { tw, uuid } from 'utils';

export const ADORNMENT_POSITION = {
  TopLeft: 'top-12 right-full -translate-x-32',
  TopRight: 'top-12 left-full translate-x-32',

  MidLeft: 'top-1/2 right-full -translate-x-32',
  MidRight: 'top-1/2 left-full translate-x-32',

  BottomLeft: 'bottom-12 right-full -translate-x-32',
  BottomRight: 'bottom-12 left-full translate-x-32'
};
export default function Adornment({
  color = 'text-gray-200',
  layoutClass,
  position = ADORNMENT_POSITION.TopLeft,
  children
}) {
  const uid = uuid();
  return (
    <svg
      className={tw('absolute transform', layoutClass || position)}
      width={404}
      height={384}
      fill={'none'}
      viewBox={'0 0 404 384'}>
      <defs>
        <pattern id={uid} x={0} y={0} width={20} height={20} patternUnits={'userSpaceOnUse'}>
          <rect x={0} y={0} width={4} height={4} className={color} fill={'currentColor'} />
        </pattern>
      </defs>
      {/* eslint-disable-next-line react/jsx-no-literals */}
      <rect width={404} height={384} fill={`url(#${uid})`} />
      {children}
    </svg>
  );
}
