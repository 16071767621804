import BlogPreview from 'components/orgamisms/BlogPreview';
import { useTranslation } from 'react-i18next';
import { tw } from 'utils';

export default function BlogPreviewDisplay({ blogposts }) {
  const { t } = useTranslation();

  return (
    <div
      id={'blog-preview'}
      className={tw('relative bg-gray-50 pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8')}>
      <div className={tw('absolute inset-0')}>
        <div className={tw('bg-white h-1/3 sm:h-2/3')} />
      </div>
      <div className={tw('relative max-w-7xl mx-auto')}>
        <div className={tw('text-center')}>
          <h2 className={tw('text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl')}>
            {t('marketing.blog.title')}
          </h2>
          <p className={tw('mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4')}>
            {t('marketing.blog.subtitle')}
          </p>
        </div>
        <div className={tw('mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none')}>
          {blogposts.map((post) => (
            <BlogPreview post={post} key={post.slug} />
          ))}
        </div>
      </div>
    </div>
  );
}
