import Banner from 'components/orgamisms/Banner';
import BlogPreviewDisplay from 'components/Templates/BlogPreviewDisplay';
import Faq from 'components/Templates/Faq';
import Featured from 'components/Templates/Featured';
// import Features from 'components/Templates/Features';
import { FullFooter } from 'components/Templates/Footer';
import Hero from 'components/Templates/Hero';
import InquiryForm from 'components/Templates/InquiryForm';
import NavbarHeader from 'components/Templates/NavbarHeader';
import WorkshopList from 'components/Templates/WorkshopList';
import { NAVIGATION_ROUTES } from 'constants/navigationPaths';
import { db } from 'dbs/adminDb';
import { last } from 'lodash';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useState } from 'react';
import { useMemo } from 'react';
import { classNames } from 'utils';

import { getBlogPosts } from './api/blogs/[slug]';
import { getPublicWorkshops } from './api/public-workshops';

export default function WelcomePage({ blogposts, publicWorkshops }) {
  const { t } = useTranslation();
  const { push } = useRouter();
  const [displayBanner, setDisplayBanner] = useState(true);

  const featured = useMemo(
    () => publicWorkshops.find((w) => w.date > Date.now()) || last(publicWorkshops),
    [publicWorkshops]
  );
  const upComing = useMemo(
    () => publicWorkshops.filter((w) => w.date > Date.now()),
    [publicWorkshops]
  );

  return (
    <div className={classNames('bg-white')}>
      <header className={classNames('mb-16')}>
        <NavbarHeader main />
      </header>

      <main>
        {displayBanner && (
          <Banner
            onClickAction={() => push(NAVIGATION_ROUTES.Workshops)}
            text={t('marketing.announcement')}
            actionText={t('marketing.banner-action-text')}
            onClose={() => setDisplayBanner(false)}
          />
        )}
        <Hero />
        <Featured workshop={featured} />
        {upComing.length > 0 && (
          <WorkshopList
            title={t('marketing.upcoming-workshops')}
            layoutClasses={classNames('lg:py-24 py-12')}
            workshops={upComing}
            maxCount={6}
          />
        )}
        {/* <Features /> */}
        <Faq />
        <BlogPreviewDisplay blogposts={blogposts} />
        <InquiryForm />
      </main>

      <FullFooter />
    </div>
  );
}

export async function getStaticProps({ locale }) {
  try {
    // const blogResult = await fetch(`${getBaseUrl()}/api/blogs/all?limit=3`, {
    //   headers: {
    //     Accept: 'application/json, text/plain, */*',
    //     'User-Agent': '*'
    //   }
    // }).then((res) => res.json());

    const blogResult = await getBlogPosts({ limit: 3 });

    const publicWorkshopsResult = (await getPublicWorkshops())?.data;
    const publicWorkshops = [];

    for (const workshop of publicWorkshopsResult) {
      const talent = (await db.doc(`talents/${workshop.talentUid}`).get()).data();
      const user = (await talent.user.get()).data();
      publicWorkshops.push({
        ...workshop,
        talent: user
      });
    }

    return {
      props: {
        ...(await serverSideTranslations(locale)),
        // Will be passed to the page component as props
        blogposts: blogResult.data,
        publicWorkshops
      }
    };
  } catch (error) {
    console.log('error @home', error);
    return {
      props: {
        ...(await serverSideTranslations(locale)),
        // Will be passed to the page component as props
        publicWorkshops: [],
        blogposts: []
      }
    };
  }
}
